<template>
  <div class="con">
    <titlebar :name="page_name" :is_custom_back="true" @goBackCustom="goBack" />
    <div class="desk-right">
      <div class="switch">
        <div class="switch-item">
          <span>优惠券名称</span>
          <input v-model="name" class="switchinput" placeholder="输入优惠券名称，不超过20个字符" />
        </div>
        <div class="switch-item">
          <span>优惠券类型</span>
          <van-radio-group v-model="type" direction="horizontal" :disabled="shop.user_model != 1">
            <van-radio name="1">满减券</van-radio>
            <van-radio name="2">折扣券</van-radio>
          </van-radio-group>
        </div>
        <div class="switch-item">
          <span>面额</span>
          <div class="s-i-field">
            <input v-if="platform_name == 'android'" v-model="face" class="face-price-input mg-rt20" readonly @click="openKeyboard('face')" />
            <input v-else v-model="face" type="number" class="face-price-input mg-rt20" />
            <span v-if="type ==  1">元</span>
            <span v-if="type ==  2">折</span>
          </div>
        </div>
        <div class="switch-item">
          <span>使用门槛</span>
          <div class="s-i-field">
            <input v-if="platform_name == 'android'" v-model="cill" class="face-price-input mg-rt20" readonly @click="openKeyboard('cill')" />
            <input v-else v-model="cill" type="number" class="face-price-input mg-rt20" />
            <span>元</span>
          </div>
        </div>
        <div class="switch-item auto-height-s-i">
          <span>有效期</span>
          <div class="s-i-field">
            <van-radio-group v-model="period" direction="horizontal" :disabled="shop.user_model != 1">
              <van-radio name="1">指定天数</van-radio>
              <van-radio name="2">指定日期</van-radio>
              <van-radio name="3">长期有效</van-radio>
            </van-radio-group>
            <div class="mg-tp40 flex flex-ac" v-if="period == 1">
              <span>领券后</span>
              <input v-if="platform_name == 'android'" v-model="day" class="face-price-input mg-lt20 mg-rt20" readonly @click="openKeyboard('day')" />
              <input v-else v-model="day" type="number" class="face-price-input mg-lt20 mg-rt20" />
              <span>天内有效</span>
              <div class="ft-22 color-666 flex flex-ac nowrap mg-lt50">
                <van-icon name="info" color="#666" size="2.6rem" class="mg-rt10" />
                例如有效期3天，7月1日领券，则有效期为7月1日 00:00:00-7月3日 23:59:59
              </div>
            </div>
            <div class="mg-tp40 flex flex-ac" v-if="period == 2">
              <input v-model="start_time" class="face-price-input" readonly placeholder="开始日期" style="width: 17rem;" @click="showCalendarStart = true;" />
              <span class="mg-lt20 mg-rt20">至</span>
              <input v-model="end_time" class="face-price-input" readonly placeholder="结束日期" style="width: 17rem;" @click="showCalendarEnd = true;" />
            </div>
          </div>
        </div>
      </div>
      <div class="foot_btn_box" v-if="shop.user_model == 1">
        <van-button v-if="id" class="foot_btn" style="margin-right: 10rem; background: #e60012; color: #fff;" @click="openDialog()">删除</van-button>
        <van-button v-else class="foot_btn" style="margin-right: 10rem;" @click="goBack">取消</van-button>
        <van-button class="foot_btn foot_submit" @click="onSubmit()">保存</van-button>
      </div>
    </div>

    <van-popup v-model="showCalendarStart" round position="bottom" class="popup-picker-custom">
      <van-datetime-picker
        v-model="defaultDate[0]"
        type="date"
        @confirm="onConfirmTime($event, 1)"
        @cancel="showCalendarStart = false"
        item-height="8.5rem"
        :formatter="formatter"
      />
    </van-popup>
    <van-popup v-model="showCalendarEnd" round position="bottom" class="popup-picker-custom">
      <van-datetime-picker
        v-model="defaultDate[1]"
        type="date"
        @confirm="onConfirmTime($event, 2)"
        @cancel="showCalendarEnd = false"
        item-height="8.5rem"
        :formatter="formatter"
      />
    </van-popup>

    <popupOfDialog
      v-if="showDialog"
      :type="dialogType"
      :title="dialogTitle"
      :message="dialogMsg"
      :data="dialogData"
      @closePopDialog="closePopDialog"
      @confirmPopDialog="confirmPopDialog"
    ></popupOfDialog>

    <van-number-keyboard 
      v-model="numVal" 
      :title="numVal" 
      :show="show_keyboard" 
      extra-key="." 
      @blur="show_keyboard = false;" 
      @input="handleNumChange" 
      @delete="handleNumDelete"
      class="otherEatNum-nk" 
    />
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import { mapGetters } from 'vuex';
import { datetimePickerFormatter, formatTime } from '../../utils/util';
import popupOfDialog from "../component/popupOfDialog.vue";
export default {
  name: "addWorker",
  components: {
    titlebar,
    popupOfDialog,
  },
  data() {
    return {
      name: "",
      type: '1', // 类型： 1满减 2折扣
      face: "", // 面额
      cill: "", // 门槛
      period: "1", // 有效期 1天数 2日期 3长期
      day: '',
      start_time: '',
      end_time: '',
      id: '',
      minDate: new Date(),
      maxDate: new Date(),
      showCalendarStart: false,
      showCalendarEnd: false,
      formatter: datetimePickerFormatter,
      startDateTime: 0,
      endDateTime: 0,
      defaultDate: [new Date(), new Date()],
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
      dialogType: "",
      dialogData: {},
      numberModel: '', // 页面数据参数
      numVal: '', // 数字键盘显示金额
      numValArr: [], // 数字键盘金额数组
      show_keyboard: false,
      platform_name: localStorage.nowDevice || '',
    };
  },
  computed: {
    ...mapGetters({shop: 'shop'})
  },
  async created() {
    this.page_name = this.$route.query.name || '';
    this.id = this.$route.query.id || '';
    if (this.id) {
      this.setInfo();
    }
  },
  methods: {
    handleNumChange(e) {
			this.numValArr.push(e);
			this.numVal = this.numValArr.join('');
			this[`${this.numberModel}`] = this.numVal;
		},
		handleNumDelete(e) {
			this.numValArr.pop();
			this.numVal = this.numValArr.length > 0 ? this.numValArr.join('') : '';
			this[`${this.numberModel}`] = this.numVal;
		},
    openKeyboard(name) {
      console.log(this[`${name}`]);
      this.numberModel = name;
      this.numVal = this[`${name}`] || '';
      this.numValArr = this[`${name}`].split('');
      this.show_keyboard = true;
    },
    delCoupon() {
      this.$api.Coupon_delCoupon({
        id: this.id,
      }).then(res => {
        if (res.code == 1) {
          this.$toast('删除成功');
          this.goBack();
        } else {
          this.$toast(res.msg);
        }
      })
    },
    confirmPopDialog() {
      this.delCoupon();
      this.closePopDialog();
    },
    closePopDialog(data) {
      this.showDialog = false;
    },
    openDialog() {
      this.dialogTitle = "提示";
      this.dialogMsg = `是否确认删除？`;
      this.dialogType = '4';
      this.dialogData = {};
      this.showDialog = true;
    },
    onConfirmTime(event, type) {
      if (type == 1) {
        if (
          this.endDateTime > 0 &&
          new Date(event).getTime() > this.endDateTime
        ) {
          this.$toast("开始日期不能大于结束日期");
          return;
        }
        this.startDateTime = new Date(event).getTime();
        this.start_time = formatTime(event, 'yyyy-MM-dd');
        this.showCalendarStart = false;
      } else {
        if (
          this.startDateTime > 0 &&
          new Date(event).getTime() < this.startDateTime
        ) {
          this.$toast("结束日期不能小于开始日期");
          return;
        }
        this.endDateTime = new Date(event).getTime();
        this.end_time = formatTime(event, 'yyyy-MM-dd');
        this.showCalendarEnd = false;
      }
    },
    setInfo() {
      this.$api.Coupon_couponInfo({
        id: this.id,
      }).then(res => {
        if (res.code == 1) {
          this.name = res.data.name;
          this.type = res.data.type.toString();
          this.face = res.data.face;
          this.cill = res.data.cill;
          this.period = res.data.period.toString();
          this.day = res.data.day || '';
          if (this.period == 2) {
            this.start_time = formatTime(new Date(res.data.start_time), 'yyyy-MM-dd');
            this.end_time = formatTime(new Date(res.data.end_time), 'yyyy-MM-dd');
          }
        } else {
          this.$toast(res.msg);
        }
      })
    },
    goBack() {
      this.$router.replace({
        name: 'coupon-manage',
        query: {
          name: 'coupon-manage',
          active: this.$route.query.active,
        },
      })
    },
    // 提交
    onSubmit(e) {
      if (this.name == '') {
        this.$toast('请输入名称');
        return;
      }
      if (this.type == 1 && (this.face == '' || !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.face))) {
        this.$toast('面额不允许为0，最多2位小数');
        return;
      }
      if (this.type == 2 && (this.face == '' || !/^(([1-9][0-9]*)|(([0]\.\d{1}|[1-9][0-9]*\.\d{1})))$/.test(this.face))) {
        this.$toast('面额不允许为0，最多1位小数');
        return;
      }
      if (this.cill == '' || !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.cill)) {
        this.$toast('使用门槛不允许为0，最多2位小数');
        return;
      }
      if (this.type == 1 && this.cill * 1 < this.face * 1) {
        this.$toast('使用门槛必须大于等于面额');
        return;
      }
      if (this.period == 1 && this.day == '') {
        this.$toast('请输入天数');
        return;
      }
      if (this.period == 1 && !/^(([1-9][0-9]*))$/.test(this.day)) {
        this.$toast('天数必须是大于0的整数');
        return;
      }
      if (this.period == 2 && (this.start_time == '' || this.end_time == '')) {
        this.$toast('请选择日期');
        return;
      }
      let data = {
        name: this.name,
        type: this.type,
        face: this.face,
        cill: this.cill,
        period: this.period,
      };
      if (this.period == 1) {
        data.day = this.day;
      }
      if (this.period == 2) {
        data.start_time = this.start_time;
        data.end_time = this.end_time;
      }
      if (this.id) {
        data.id = this.id;
        this.editCoupon(data);
      } else {
        this.addCoupon(data);
      }
    },
    addCoupon(query) {
      this.$api.Coupon_addCoupon(query).then(res => {
        if (res.code == 1) {
          this.$toast(res.msg || '新增成功');
          this.goBack();
        } else {
          this.$toast(res.msg);
        }
      })
    },
    editCoupon(query) {
      this.$api.Coupon_editCoupon(query).then(res => {
        if (res.code == 1) {
          this.$toast('编辑成功');
          this.goBack();
        } else {
          this.$toast(res.msg);
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.otherEatNum-nk {
  border-radius: 0.13rem;
  width: 30vw;
  left: 35vw;
  z-index: 9999;
}
.desk-right {
  height: calc(100% - 10.6rem);
  background-color: #fff;
  margin-top: 1.5rem;

  .switch {
    // margin-top: 0.2rem;
    background: #fff;
    padding-bottom: 2rem;

    .switch-item {
      font-size: 2.4rem;
      height: 9.5rem;
      background-color: #fff;
      // margin-top: 0.05rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1.2rem;
      border-bottom: 0.15rem solid #eeeeee;
      width: calc(100% - 16.8rem);
      margin: 0 4rem;
    }

    .spt-btns {
      display: flex;
      margin: 5rem auto 2.5rem;
      justify-content: center;
    }

    .switchinput {
      // border: 0.01rem #eeeeee solid;
      border-radius: 0.8rem;
      height: 70%;
      width: 80%;
    }

    .s-i-field {
      width: 80%;
      text-align: left;
    }

    /deep/.van-radio-group {
      height: 100%;
      width: 80%;
    }

    .auto-height-s-i {
      min-height: 9.5rem;
      height: auto;
      padding: 2.5rem 1.2rem;
    }
  }

  .foot_btn_box {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    bottom: 0;
    background: #fff;
    z-index: 10;
    height: 10rem;
    align-items: center;
    box-shadow: 0 0 0.7rem 0.7rem rgba(229, 229, 229, 0.26);
    left: 0;

    .foot_btn {
      font-size: 2.4rem;
      width: 42rem;
      height: 7rem;
      border-radius: 0.8rem;
      background: #eaeaea;
    }

    .foot_submit {
      color: #fff;
      background-color: #1588f5;
    }
  }

  .add-food {
    height: 100%;
    margin-top: 2rem;
    overflow: scroll;
    overflow-x: hidden;

    .van-cell.van-field {
      font-size: 2.6rem;
      padding: 2rem 3rem;

      /deep/.van-cell__title span {
        line-height: 6rem;
      }

      /deep/.van-field__control {
        min-height: 6rem;

        .van-radio__icon {
          font-size: 2.6rem;
        }

        .van-radio__icon--checked {
          font-size: 2.6rem;

          .van-icon {
            background-color: #1588F5;
            border-color: #1588F5;
          }
        }
      }
    }

    .van-cell.van-field {
      font-size: 2.6rem;

      /deep/textarea.van-field__control::placeholder {
        transform: translateY(0.1rem);
      }

      /deep/.van-field__word-limit {
        font-size: 2.4rem;
      }
    }

    .van-button--normal {
      font-size: 2.6rem;
      background-color: #1588F5;
      border-color: #1588F5;
    }
  }
}

.editdesk-btn {
  width: 42rem;
  height: 8rem;
  border-radius: 0.8rem;
  border: 0;
}

.con {
  height: 100%;
}

.face-price-input {
  width: 11rem;
  height: 6.2rem;
  border: 0.2rem solid #DEDEDE;
  border-radius: 0.8rem;
  padding: 0 2rem;
  font-size: 2.4rem;
}
</style>
